import * as React from 'react';
import { graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import FlashLayout from '../components/FlashLayout';
import { usePresentacionAnalisisPost } from '../operations/queries';
import getCategoryColor from '../utils/getCategoryColor';
import { PostPdf as PostPdfProps } from '../models/PostPdf';
import options from '../utils/getRichTextOptions';
import PDFPreview from '../components/PdfPreview';
import { renderRichText } from "gatsby-source-contentful/rich-text"
import useIsClient from '../utils/isClient';
import { HeadApi } from '../components/HeadApi';

interface Props {
  data: {
    contentfulPresentacionAnalisis: any;
  };
}

export const Head = ({data}) => {
  const post: PostPdfProps = usePresentacionAnalisisPost(
    data.contentfulPresentacionAnalisis
  );
  // console.log("[POST HEAD]", post);
  return(
    <HeadApi
      title={post.title}
      description={post.description}
      img={post.fluid?.gatsbyImageData.images.fallback.src}
    />
  );
};

export default function ReporteTrimestral({ data }: Props): JSX.Element {
  const color = getCategoryColor('general');

  const post: PostPdfProps = usePresentacionAnalisisPost(
    data.contentfulPresentacionAnalisis
  );

  const isClient = useIsClient();
  if(isClient){
    return (
      <FlashLayout post={post} color={color}>
        <>
          <h2>{post.description}</h2>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={3}>
              {/* <IconTitle
                title="Recomendaciones"
                icon={Wizard}
                table={recomendaciones}
                card
              /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <PDFPreview pdf={post.pdf} />
            </Grid>
            <Grid item xs={12} sm={12} md={3} />
  
            <Grid item xs={12} sm={12} md={3} />
  
            <Grid item xs={12} sm={12} md={6}>
              {post.text && renderRichText(post.text, options)}
            </Grid>
          </Grid>
        </>
      </FlashLayout>
    );
  }else{
    return(<></>)
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulPresentacionAnalisis(slug: { eq: $slug }) {
      ...presentacionAnalisis
    }
  }
`;
